import React from "react";
import { getPathName } from "./utils/pathname";
import { UserData } from "../src/userDataContext";

interface Tracking {
    track: (path: string, options: { Path: string }) => void;
}

const defaultTracking = {
    track: () => null
} as Tracking;

export const MixpanelContext = React.createContext(defaultTracking);

export const trackMixpanelOnScroll = (userData: UserData["data"]) => {
    const onScroll = () => {
        const docHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );

        if (window.scrollY > docHeight / 2) {
            window.removeEventListener("scroll", onScroll);

            let trackingParams: {
                "Payment Plan"?: string;
                "Username"?: string;
                "Email"?: string;
                "Path": string;
            } = {
                Path: getPathName()
            };

            const {
                paymentPlan,
                username,
                email
            } = userData;

            if (username) {
                trackingParams = {
                    ...trackingParams,
                    "Payment Plan": paymentPlan,
                    "Username": username,
                    "Email": email
                };
            }

            mixpanel.track("Scrolled the page down by 50%", trackingParams);
        }
    };

    const isOptedOut = userData.privacyPreferences.trackingOptOut.value;

    if (!isOptedOut) {
        window.removeEventListener("scroll", onScroll);
        window.addEventListener("scroll", onScroll);
        onScroll();
    }
};
