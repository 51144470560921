import Cookies from "js-cookie";
import React, { ReactElement } from "react";

type UserData = {
    data: {
        username?: string;
        email?: string;
        paymentPlan?: string;
        privacyPreferences: {
            trackingOptOut: {
                value: boolean;
            }
        }
    },
    loggedIn: boolean;
};

const defaultState: UserData = {
    data: {
        username: undefined,
        email: undefined,
        paymentPlan: undefined,
        privacyPreferences: {
            trackingOptOut: {
                value: false
            }
        }
    },
    loggedIn: false
};

const UserDataContext = React.createContext(defaultState);

const UserDataProvider = ({ children }: { children: ReactElement[] }) => {
    const [userData, setUserData] = React.useState(defaultState);

    React.useEffect(() => {
        const token = Cookies.get("userToken");

        if (token) {
            setUserData(prevState => ({
                ...prevState,
                loggedIn: true
            }));

            // setTimeout(() => {
            //     setUserData(prevState => ({
            //         ...prevState,
            //         data: {
            //             ...prevState.data,
            //             _id: 123
            //
            //         }
            //     }))
            // }, 2000)

            fetch(
                `https://${process.env.API_HOST}/v2/users`,
                {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Zeplin-Token": token
                    })
                }
            )
                .then(response => response.json()
                    .then(data => {
                        return {
                            data,
                            status: response
                        };
                    }))
                .catch(err => console.log(err))
                .then(resp => {
                    if (resp?.status.ok) {
                        setUserData(prevState => ({
                            ...prevState,
                            data: resp.data
                        }));
                    } else {
                        Cookies.remove("userToken", {
                            domain: process.env.COOKIE_DOMAIN?.substring(1)
                        });
                        setUserData(prevState => ({
                            ...prevState,
                            loggedIn: false
                        }));
                    }
                });
        }
    }, []);

    return (
        <UserDataContext.Provider value={userData}>
            {children}
        </UserDataContext.Provider>
    );
};

export type { UserData };

export default UserDataContext;

export { UserDataProvider };
