/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react";
import mixpanel from "mixpanel-browser";
import Cookies from "js-cookie";
import { MixpanelContext } from "./src/tracking";
import { UserDataProvider } from "./src/userDataContext"

export const wrapRootElement = ({ element }) => {
    fetch(`https://${process.env.API_HOST}/api/config`, {
        method: "GET",
    })
    .then((response) => response.json())
    .then((resp) => {
        if (!resp && !resp.url.mixpanelProxy) return;

        mixpanel.init(process.env.MIXPANEL_PROJECT_KEY, {
            api_host: resp.url.mixpanelProxy
        });

        return (
            <UserDataProvider>
                <MixpanelContext.Provider value={mixpanel}>
                    {element}
                </MixpanelContext.Provider>
            </UserDataProvider>
        );

    })
    .catch((error) => {
        console.error('Error:', error);
    });
};


/* globals window */
export const onInitialClientRender = () => {
    fetch(`https://${process.env.API_HOST}/api/config`, {
        method: "GET",
    })
    .then((response) => response.json())
    .then((resp) => {
        if (!resp && !resp.url.mixpanelProxy) return;

        mixpanel.init(process.env.MIXPANEL_PROJECT_KEY, {
            api_host: resp.url.mixpanelProxy
        });

        const sameSite = process.env.SECURE_COOKIE ? "none" : "lax";

        Cookies.defaults = {
            path: "/",
            domain: process.env.COOKIE_DOMAIN,
            expires: Infinity,
            secure: process.env.SECURE_COOKIE,
            sameSite
        };

        // Process UTM params and save it
        const utmParams = {};

        window.location.search.substr(1).split("&").forEach(param => {
            const kv = param.split("=").map(decodeURIComponent);

            const utmParam = kv[0].match(/^utm_(\w+)$/);
            if (utmParam) {
                [, utmParams[utmParam[1]]] = kv;
            }
        });

        if (Object.keys(utmParams).length) {
            Cookies.set("utmParams", JSON.stringify(utmParams));
        }

    })
    .catch((error) => {
        console.error('Error:', error);
    });
}
